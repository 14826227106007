<template>
  <section class="uri-synopsis-card grid">
    <div class="card-grid">
      <figure v-for="(card, cardIndex) in block.data" :key="'card-' + cardIndex" class="card">
        <router-link :to="card.path">
          <div v-if="card.img" class="card-image">
            <img :src="card.img.src" :srcset="card.img.srcset" :sizes="card.img.sizes" :alt="card.img.alt" :title="card.img.title" :width="card.img.width" :height="card.img.height" />
          </div>
        </router-link>
        <figcaption class="card-body">
          <div class="card-header">
            <router-link :to="card.path">
              <div class="card-title">{{ card.title }}</div>
            </router-link>
          </div>
          <div v-if="card.subtitle" class="card-subtitle">{{ card.subtitle }}</div>
          {{ card.synopsis }}
        </figcaption>
      </figure>
    </div>
  </section>
</template>

<style>
.uri-synopsis-card.grid {
  overflow: unset;
}
.uri-synopsis-card .card-grid {
  display: grid;
}
</style>

<script>
export default {
  name: 'BlockUriSynopsisCardGrid',
  props: {
    block: Object,
  },
  computed: {
  },
  data() {
    return {};
  },
};
</script>
